<template>
  <div>
    <!-- loader -->
    <template>
      <v-overlay :value="loader">
        <!-- <loader></loader> -->
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </template>
    <!-- snackbar -->
    <template>
      <v-snackbar :color="snackbar.color" v-model="snackbar.show" :timeout="snackbar.timeout || 1234">
        <span v-html="snackbar.text"></span>
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </template>
    <!-- delete alert dialog -->
    <template>
      <v-dialog v-model="deleteDialog" width="500">
        <v-card>
          <v-card-title class="error white--text py-3 px-4">
            <span class="body-1 font-weight-bold"> Delete Confirmation! </span>
            <v-spacer></v-spacer>
            <v-icon dark size="22"> mdi-alert </v-icon>
          </v-card-title>
          <v-card-text class="py-3 px-4">
            <span class="subtitle-2"> Are you sure to delete? </span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="confirmDelete"> delete </v-btn>
            <v-btn color="success" elevation="2" @click="deleteDialog = false"> cancel </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
export default {
  data () {
    return {
      // loader
      loader: false,
      // snackbar
      snackbar: {
        show: false,
        color: null,
        message: '',
        timeout: 0
      },
      // delete
      deleteDialog: false,
      deletePayload: null
    }
  },
  components: {
    // loader: () => import(/* webpackChunkName: "loader" */ '@/components/Loader.vue')
  },
  mounted () {
    this.$root.$on('snackbar', (data) => {
      Object.assign(this.snackbar, { ...data, show: true })
    })
    this.$eventBus.$on('snackbar', (data) => {
      Object.assign(this.snackbar, { ...data, show: true })
    })
    this.$root.$on('delete', (data) => {
      this.deletePayload = data
      this.deleteDialog = true
    })
    this.$root.$on('loader', (data) => {
      this.loader = data
    })
  },
  methods: {
    confirmDelete () {
      const { url, _id, success } = this.deletePayload
      this.$_execute('delete', `${url}/${_id}`)
        .then(() => {
          this.$root.$emit('snackbar', { color: 'success', text: 'Deleted successfully!' })
          if (success) success()
        })
        .catch((err) => this.$_handleApiError(err))
        .finally(() => this.deleteDialog = false)
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('snackbar')
    this.$root.$off('snackbar')
    this.$root.$off('delete')
    this.$root.$off('loader')
  }
}
</script>
